import { BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import { LayoutTemplate } from './templates/Layout.template.tsx'
import { createTheme, ThemeProvider, createSvgIcon, CssBaseline } from '@mui/material'
import { errorFallback } from './components/error/FullpageError.tsx'
import { FullpageContainer } from './components/error/FullpageContainer.tsx'
import { Suspense } from 'react'
import { SnackbarProvider } from './hooks/SnackbarContext.tsx'
import { grey } from '@mui/material/colors'
import { ErrorBoundary } from 'react-error-boundary'

const theme = createTheme({
  components: {
    MuiTabs: {
      defaultProps: {
        slotProps: {
          indicator: {
            hidden: true
          }
        }
      },
      styleOverrides: {
        root: {
          border: '1px solid #218c47',
          'border-radius': '4px'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          'border-left': '1px solid #218c47',
          'background-color': 'white',
          color: '#218c47',
          outline: 'none',
          ':first-child': {
            'border-left': 0
          },
          '&.Mui-selected': {
            background: 'linear-gradient(180deg, #78CF45, #30A049, 100%)',
            color: 'white'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          'background-color': 'white',
          color: '#0f1828',
          variants: [
            {
              props: { variant: 'outlined' },
              style: {
                backgroundColor: '#f3f3f3',
                border: 0,
                color: '#8a8a8a'
              }
            }
          ]
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.selected > svg, &.gradient > svg': {
            fill: 'url(#myGradient)'
          },
          '&.MuiIconButton-colorPrimary:not(:disabled)': {
            color: '#43ac48'
          },
          '&:disabled': {
            color: grey[500],
            cursor: 'not-allowed'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#43ac48',
          textDecoration: 'none'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { color: 'primary' },
              style: {
                background: 'linear-gradient(180deg, #78cf44, #31a049)',
                color: 'white',
                ':disabled': {
                  background: `linear-gradient(180deg, ${grey[300]}, ${grey[400]})`,
                  color: grey[500],
                  cursor: 'not-allowed'
                }
              }
            },
            {
              props: { size: 'small' },
              style: {
                padding: '6px 16px',
                fontSize: '0.875rem'
              }
            }
          ],
          ':disabled': {
            background: `linear-gradient(180deg, ${grey[300]}, ${grey[400]})`,
            color: grey[500],
            cursor: 'not-allowed'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            borderRadius: '4px',
            '::before, :hover::before': {
              borderBottom: '0'
            },
            '& input': {
              paddingTop: '9px'
            },
            '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
              marginTop: '2px'
            }
          }
        }
      }
    }
  }
})

const GradientDefinition = createSvgIcon(
  <linearGradient id="myGradient" x1="0%" y1="0%" x2="0%" y2="100%" height="0" width="0">
    <stop offset="0%" stopColor="#78CF45" />
    <stop offset="100%" stopColor="#30A049" />
  </linearGradient>,
  'GradientDefinition'
)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <ErrorBoundary fallbackRender={errorFallback}>
          <Suspense
            fallback={
              <FullpageContainer>
                <h4>Loading...</h4>
              </FullpageContainer>
            }
          >
            <Router>
              <GradientDefinition style={{ width: '0', height: '0', position: 'absolute' }} />
              <CssBaseline />
              <LayoutTemplate />
            </Router>
          </Suspense>
        </ErrorBoundary>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
