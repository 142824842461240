import { z } from 'zod'
import { PrivateMessageSchema } from './privatemessage'

export const PrivateDiscussionSchema = z.object({
  id: z.string().optional(),
  user1: z.string(),
  user2: z.string(),
  lastMessage: PrivateMessageSchema.pick({ id: true, message: true, user: true, userId: true, created: true, updated: true }).optional(),
  unreadCount: z.number(),
})

export type PrivateDiscussion = z.infer<typeof PrivateDiscussionSchema>
