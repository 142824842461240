import { z } from 'zod'
import { MessageBaseSchema, ParentMessageSchema } from './messagebase'

export const PrivateMessageSchema = MessageBaseSchema.omit({ roomId: true }).extend({
  discussionId: z.string(),
  parent: ParentMessageSchema.optional()
})

export const NewPrivateMessageSchema = PrivateMessageSchema.omit({ id: true, created: true, updated: true, userId: true, user: true })

export type PrivateMessage = z.infer<typeof PrivateMessageSchema>
export type NewPrivateMessage = z.infer<typeof NewPrivateMessageSchema>
