export * from './attachment'
export * from './message'
export * from './messagebase'
export * from './rating'
export * from './room'
export * from './thread'
export * from './roomchat'
export * from './user'
export * from './privatemessage'
export * from './privatediscussion'
export * from './friendrequest'
export * from './friends'
export * from './notification'
export * from './location'
export * from './upload'
export * from './globalmessage'
export * from './feeditem'
export * from './updates'

export enum ModelType {
  Attachment = 'Attachment',
  Message = 'Message',
  MessageBase = 'MessageBase',
  Rating = 'Rating',
  Room = 'Room',
  Thread = 'Thread',
  RoomChat = 'RoomChat',
  User = 'User',
  PrivateMessage = 'PrivateMessage',
  PrivateDiscussion = 'PrivateDiscussion',
  FriendRequest = 'FriendRequest',
  Friends = 'Friends',
  Notification = 'Notification',
  Location = 'Location',
  Upload = 'Upload',
  GlobalMessage = 'GlobalMessage',
  FeedItem = 'FeedItem',
  Updates = 'Updates',
  OnlineStatus = 'OnlineStatus',
}

export enum ModelAction {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete'
}
