import { getAxios } from './axios.ts'
import { API_BASE_URL } from '../constants/env.ts'
import { FriendInfoEnrichedWithDiscussionId } from '../types/entities/User.ts'
import { PrivateDiscussion } from '../types/entities/PrivateDiscussion.ts'
import { PrivateMessage } from '@petshop/common'

export const fetchPrivateDiscussions = async (): Promise<FriendInfoEnrichedWithDiscussionId[]> => {
  const response = await getAxios().get(`${API_BASE_URL}/chat/discussion`)
  return response.data
}

export const fetchPrivateDiscussion = async (receiverUserId: string) => {
  return (await getAxios().get<PrivateDiscussion>(`${API_BASE_URL}/chat/discussion/get/${receiverUserId}`)).data
}

export const getPrivateMessages = async (discussionId: string, after?: number) => {
  return (await getAxios().get<PrivateMessage[]>(`${API_BASE_URL}/chat/discussion/${discussionId}/message/byDiscussion/${after}`)).data
}
export const postPrivateMessage = async (discussionId: string, message: PrivateMessage) => {
  return (await getAxios().post<PrivateMessage>(`${API_BASE_URL}/chat/discussion/${discussionId}/message`, message)).data
}

export const putPrivateMessage = async (discussionId: string, message: PrivateMessage) => {
  return (await getAxios().put<PrivateMessage>(`${API_BASE_URL}/chat/discussion/${discussionId}/message/${message.id}`, message)).data
}

export const deletePrivateMessage = async (discussionId: string, messageId: string) => {
  return (await getAxios().delete(`${API_BASE_URL}/chat/discussion/${discussionId}/message/${messageId}`)).data
}

export const votePrivateMessage = async (discussionId: string, messageId: string) => {
  return (await getAxios().put<PrivateMessage>(`${API_BASE_URL}/chat/discussion/${discussionId}/message/${messageId}/vote`, { vote: 'up' }))
    .data
}

export const unvotePrivateMessage = async (discussionId: string, messageId: string) => {
  return (await getAxios().delete<PrivateMessage>(`${API_BASE_URL}/chat/discussion/${discussionId}/message/${messageId}/vote`)).data
}
