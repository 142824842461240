import { z } from 'zod'
import { AttachmentSchema } from './attachment'
import { BaseModel } from './basemodel'

export const MessageBaseSchema = BaseModel.extend({
  roomId: z.string().min(5),
  userId: z.string(),
  user: z.string(),
  message: z.string().min(2),
  taggedUsers: z.array(z.string()).optional(),
  rating: z.number().optional(),
  attachments: z.array(AttachmentSchema).optional(),
  deleted: z.boolean().optional(),
  voted: z.number().min(-1).max(1).optional()
})

export const ParentMessageSchema = MessageBaseSchema.pick({ id: true, user: true, userId: true, message: true, updated: true })

export type MessageBase = z.infer<typeof MessageBaseSchema>
export type ParentMessage = z.infer<typeof ParentMessageSchema>
