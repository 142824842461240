import { z } from 'zod'

export const PresignedUrlRequestSchema = z.object({
  roomId: z.string().optional(),
  filename: z.string(),
  mimetype: z.string(),
})

export const PresignedUrlResponseSchema = z.object({
  uploadUrl: z.string(),
  fileId: z.string(),
})

export const FinalizeUploadRequestSchema = z.object({
  fileId: z.string(),
  mimetype: z.string(),
  filename: z.string()
})

export const UploadSchema = FinalizeUploadRequestSchema.extend({
  id: z.string(),
  userId: z.string(),
  created: z.string().datetime().optional(),
  updated: z.string().datetime().optional(),
  deleted: z.boolean()
})


export type PresignedUrlResponse = z.infer<typeof PresignedUrlResponseSchema>
export type FinalizeUploadRequest = z.infer<typeof FinalizeUploadRequestSchema>
export type Upload = z.infer<typeof UploadSchema>
