import { PublicUser, Upload, User } from '@petshop/common'
import { getAxios } from './axios.ts'
import { API_BASE_URL } from '../constants/env.ts'

export const getUser = async (userId: string) => {
  return (await getAxios().get<PublicUser>(`${API_BASE_URL}/user/${userId}`)).data
}

export const putUser = async (userId: string, user: Partial<User>) => {
  return (await getAxios().put<User>(`${API_BASE_URL}/user/${userId}`, user)).data
}

export const fetchUsersByIds = async (ids: string[]) => {
  return (await getAxios().post<PublicUser[]>(`${API_BASE_URL}/user/list/byId`, { ids })).data
}

export const fetchUploads = async (userId: string) => {
  return (await getAxios().get<Upload[]>(`${API_BASE_URL}/user/upload/${userId}`)).data
}
