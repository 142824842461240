import { Message } from '@petshop/common'
import { getAxios } from './axios.ts'
import { API_BASE_URL } from '../constants/env'
import { NewMessage } from '../types/entities/Message.ts'

export const getMessages = async (roomId: string, threadId: string, after?: number) => {
  return (await getAxios().get<Message[]>(`${API_BASE_URL}/room/${roomId}/thread/${threadId}/message/byThread/${after}`)).data
}

export const postMessage = async (roomId: string, threadId: string, message: NewMessage) => {
  return (await getAxios().post<Message>(`${API_BASE_URL}/room/${roomId}/thread/${threadId}/message`, message)).data
}
export const putMessage = async (roomId: string, threadId: string, message: Message) => {
  return (await getAxios().put<Message>(`${API_BASE_URL}/room/${roomId}/thread/${threadId}/message/${message.id}`, message)).data
}

export const voteMessage = async (roomId: string, threadId: string, messageId: string, vote: 'up' | 'down') => {
  return (await getAxios().put<Message>(`${API_BASE_URL}/room/${roomId}/thread/${threadId}/message/${messageId}/vote`, { vote })).data
}

export const unvoteMessage = async (roomId: string, threadId: string, messageId: string) => {
  return (await getAxios().delete<Message>(`${API_BASE_URL}/room/${roomId}/thread/${threadId}/message/${messageId}/vote`)).data
}

export const removeMessage = async (roomId: string, threadId: string, messageId: string) => {
  return (await getAxios().delete<Message>(`${API_BASE_URL}/room/${roomId}/thread/${threadId}/message/${messageId}`)).data
}
