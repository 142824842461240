import { API_BASE_URL } from '../constants/env.ts'
import { Notification } from '../types/entities/Notification.ts'
import { getAxios } from './axios.ts'

export async function fetchNotifications(): Promise<Notification[]> {
  return (await getAxios().get<Notification[]>(`${API_BASE_URL}/notification`)).data
}

export async function markNotificationAsRead(id: string): Promise<void> {
  return (await getAxios().put(`${API_BASE_URL}/notification/${id}/read`)).data
}
