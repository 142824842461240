import { useState, useCallback, ReactNode } from 'react'
import { Snackbar, SnackbarProps } from '@mui/material'

export const useSnackbar = () => {
  const [snackbars, setSnackbars] = useState<SnackbarProps[]>([])

  const showSnackbar = useCallback((children: SnackbarProps['children'], options: Omit<SnackbarProps, 'open' | 'message'> = {}) => {
    setSnackbars((prev) => [
      ...prev,
      { children, open: true, autoHideDuration: 3000, anchorOrigin: { horizontal: 'center', vertical: 'top' }, ...options }
    ])
  }, [])

  const handleClose = useCallback((index: number) => {
    setSnackbars((prev) => {
      const newSnackbars = [...prev]
      newSnackbars[index] = { ...newSnackbars[index], open: false }
      return newSnackbars
    })
  }, [])

  const SnackbarComponents: ReactNode[] = snackbars.map((props, index) => (
    <Snackbar key={index} {...props} onClose={() => handleClose(index)}>
      {props.children}
    </Snackbar>
  ))

  return [SnackbarComponents, showSnackbar] as const
}
