import { z } from 'zod'
import { RoomChatSchema } from './roomchat'

export const RoomSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  description: z.string().optional(),
  private: z.boolean(),
  active: z.boolean(),
  created: z.string().datetime().optional(),
  updated: z.string().datetime().optional(),
  owner: z.string(),
  admins: z.array(z.string()).optional(),
  members: z.array(z.string()).optional(),
  avatarUrl: z.string().optional(),
  lastRoomChat: RoomChatSchema.pick({ id: true, message: true, userId: true, user: true, created: true, updated: true }).optional().nullable(),
  colors: z
    .object({
      background: z.string(),
      highlight: z.string(),
      text: z.string()
    })
    .optional()
})

export type Room = z.infer<typeof RoomSchema>
