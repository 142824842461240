import { ReactNode, FC } from 'react'
import { Box } from '@mui/material'

type Props = {
  children: ReactNode
}

export const FullpageContainer: FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: '#fff',
        overflow: 'hidden'
      }}
    >
      {children}
    </Box>
  )
}
