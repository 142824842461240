import { atom } from 'jotai'
import { atomWithRefresh, atomWithDefault } from 'jotai/utils'
import { fetchFriendsRequest, fetchFriends, getFriendsOnline } from '../api/friends.ts'
import { fetchNotifications } from '../api/notification.ts'
import { fetchRooms } from '../api/room.ts'
import { fetchPrivateDiscussions, fetchPrivateDiscussion, getPrivateMessages } from '../api/privatechats.ts'
import { PrivateDiscussion, Thread, Notification } from '@petshop/common'
import dayjs from 'dayjs'
import { getUser, fetchUploads, fetchUsersByIds } from '../api/user.ts'
import { atomWithCallback, createDataAtom } from './utils.ts'
import { fetchFeed } from '../api/feed.ts'
import { getMessages } from '../api/message.ts'
import { getRoomChatsByRoomAfter } from '../api/roomchat.ts'

export const userIdAtom = atom<string | undefined>(undefined)

export const roomIdAtom = atom<string | undefined>(undefined)
export const roomsAtom = atomWithDefault(async () => {
  return fetchRooms()
})

export const { dataAtom: feedDataAtom, setItemAtom: setFeedDataAtom } = createDataAtom(async () => {
  return fetchFeed(dayjs().subtract(14, 'days').toDate().getTime())
})

export const threadAtom = atom<Thread | undefined>(undefined)

export const { dataAtom: messageDataAtom, setItemAtom: setMessageDataAtom } = createDataAtom(async (get) => {
  const roomId = get(roomIdAtom)
  const threadId = get(threadAtom)?.id
  if (!roomId || !threadId) {
    return []
  }
  return await getMessages(roomId, threadId)
})

export const { dataAtom: friendRequestsAtom, setItemAtom: setFriendRequestsAtom } = createDataAtom(async () => {
  return fetchFriendsRequest()
})
export const { dataAtom: friendsAtom, setItemAtom: setFriendsAtom } = createDataAtom(async () => {
  return fetchFriends()
})

export const { dataAtom: notificationsAtom, setItemAtom: setNotificationsAtom } = createDataAtom<Notification>(async () => {
  return fetchNotifications()
})

export const { dataAtom: roomChatsAtom, setItemAtom: setRoomChatsAtom } = createDataAtom(async (get) => {
  const roomId = get(roomIdAtom)
  return getRoomChatsByRoomAfter(roomId!, dayjs().subtract(14, 'days').toDate().getTime())
})

export const privateDiscussionFriendIdAtom = atom<string | undefined>(undefined)
export const privateDiscussionIdAtom = atom<string | undefined>(undefined)

export const privateDiscussionAtom = atomWithRefresh<Promise<PrivateDiscussion | undefined>>(async (get) => {
  const friendId = get(privateDiscussionFriendIdAtom)
  if (!friendId) {
    return undefined
  }
  return fetchPrivateDiscussion(friendId)
})

export const { dataAtom: privateDiscussionsAtom, setItemAtom: setPrivateDiscussionsAtom } = createDataAtom(async () => {
  return fetchPrivateDiscussions()
})

export const { dataAtom: privateMessagesAtom, setItemAtom: setPrivateMessagesAtom } = createDataAtom(async (get) => {
  const discussion = await get(privateDiscussionAtom)
  if (!discussion) {
    return []
  }
  return getPrivateMessages(discussion.id!, dayjs().subtract(14, 'day').toDate().getTime())
})

export const profileIdAtom = atom<string | undefined>(undefined)
export const profileAtom = atomWithRefresh(async (get) => {
  const profileId = get(profileIdAtom)
  if (!profileId) {
    return undefined
  }
  return getUser(profileId)
})

export const uploadsAtom = atomWithRefresh(async (get) => {
  const userId = get(userIdAtom)
  const profileId = get(profileIdAtom)
  if (!userId || userId !== profileId) {
    return []
  }
  return fetchUploads(profileId)
})

export const userCacheAtom = atomWithCallback(async (newItems) => {
  if (!newItems.length) {
    return []
  }
  return fetchUsersByIds(newItems)
}, 'id')

export const { dataAtom: friendsOnlineAtom, setItemAtom: setFriendsOnlineAtom } = createDataAtom(async () => {
  return getFriendsOnline()
})
