import { z } from 'zod'

export const FriendsSchema = z.object({
  id: z.string().optional(),
  userId: z.string(),
  friendIds: z.array(z.string()),
  created: z.string().datetime().optional(),
  updated: z.string().datetime().optional()
})

export const FriendsOnlineSchema = z.object({
  userId: z.string(),
  userStatus: z.enum(['online', 'offline']),
  lastSeen: z.string().datetime()
})

export type Friends = z.infer<typeof FriendsSchema>
export type FriendsOnline = z.infer<typeof FriendsOnlineSchema>
