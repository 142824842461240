import { z } from 'zod'
import { GlobalMessageSchema } from './globalmessage'
import { ThreadSchema } from './thread'
import { RoomChatSchema } from './roomchat'

export enum FeedItemType {
  GlobalMessage = 'GlobalMessage',
  Thread = 'Thread',
  RoomChat = 'RoomChat'
}

const FeedGlobalMessageSchema = GlobalMessageSchema.extend({
  type: z.literal(FeedItemType.GlobalMessage)
})

const FeedThreadSchema = ThreadSchema.extend({
  type: z.literal(FeedItemType.Thread)
})

const FeedRoomChatSchema = RoomChatSchema.extend({
  type: z.literal(FeedItemType.RoomChat)
})

export const FeedItemSchema = z.discriminatedUnion('type', [FeedGlobalMessageSchema, FeedThreadSchema, FeedRoomChatSchema])
export type FeedItem = z.infer<typeof FeedItemSchema>
