import { jwtDecode } from 'jwt-decode'

type DecodedToken = {
  exp: number
  id: string
}

export const validateToken = (token: string) => {
  if (token) {
    try {
      const decoded: DecodedToken = jwtDecode(token)
      const currentTime = Date.now() / 1000
      if (decoded.exp > currentTime) {
        return true
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      return false
    }
  }
  return false
}

export const getUserIdFromToken = (token: string) => {
  if (token) {
    try {
      const decoded: DecodedToken = jwtDecode(token)
      return decoded.id
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      return null
    }
  }
  return null
}
