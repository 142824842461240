import { getAxios } from './axios.ts'
import { API_BASE_URL } from '../constants/env.ts'
import { FeedItem } from '@petshop/common'

export async function fetchFeed(after?: number) {
  return (await getAxios().get<FeedItem[]>(`${API_BASE_URL}/feed/after${after ? `/${after}` : ''}`)).data
}

export const fetchFeedRange = async (min: number, max: number) => {
  return (await getAxios().get<FeedItem[]>(`${API_BASE_URL}/feed/range/${min}/${max}`)).data
}
