import { atom, Getter } from 'jotai'
import { atomWithRefresh, atomWithDefault } from 'jotai/utils'

export function atomWithCallback<T>(callback: (newItems: string[]) => T[] | Promise<T[]>, cacheKey: keyof T) {
  const cacheAtom = atom<Map<string, T>>(new Map())

  const derivedAtom = atom(
    (get) => {
      const cache = get(cacheAtom)
      return [...cache.values()]
    },
    async (get, set, newList: string[], invalidate: string[] = []) => {
      const cache = get(cacheAtom)
      const currentList = [...cache.keys()]

      const withoutInvalidates = currentList.filter((id) => !invalidate.includes(id))

      invalidate.forEach((id) => {
        cache.delete(id)
      })

      const newItems = [...new Set(newList.filter((item) => !withoutInvalidates.includes(item)))]

      const items = await callback(newItems)
      items.forEach((result) => {
        if (result) {
          cache.set(result[cacheKey] as string, result)
        }
      })

      set(cacheAtom, new Map(cache))
    }
  )

  return derivedAtom
}

export function createDataAtom<T>(initialLoad: (get: Getter) => Promise<T[]>, itemLimit: number = Infinity) {
  const loadAtom = atomWithRefresh(async (get) => {
    const data = await initialLoad(get)
    return data.slice(-itemLimit)
  })
  const overwriteAtom = atom<T[]>([])
  const dataAtom = atomWithDefault(async (get) => {
    const initialData = await get(loadAtom)
    const overwrittenData = get(overwriteAtom)
    return overwrittenData.length > 0 ? overwrittenData : initialData
  })

  const setItemAtom = atom(null, async (_get, set, items: T[]) => {
    set(overwriteAtom, items.slice(-itemLimit))
  })

  return { dataAtom, setItemAtom }
}
