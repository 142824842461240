import { BaseModel } from './basemodel'
import { z } from 'zod'

export const UpdatesSchema = BaseModel.extend({
  userId: z.string(),
  roomChats: z.record(z.number()),
  privateMessages: z.record(z.number())
})

export type Updates = z.infer<typeof UpdatesSchema>
