import { createContext, useContext, ReactNode } from 'react'
import { useSnackbar } from './useSnackbar'

const SnackbarContext = createContext<ReturnType<typeof useSnackbar> | undefined>(undefined)

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const snackbar = useSnackbar()
  return <SnackbarContext.Provider value={snackbar}>{children}</SnackbarContext.Provider>
}

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error('useSnackbarContext must be used within a SnackbarProvider')
  }
  return context
}
