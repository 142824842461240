import { useState, useEffect } from 'react'
import { refresh } from '../api/axios.ts'
import { validateToken, getUserIdFromToken } from '../utils/validateToken.ts'

export const useAuth = () => {
  const [token, setToken] = useState<string | null>(() => {
    const storedToken = sessionStorage.getItem('accessToken')
    return storedToken ? JSON.parse(storedToken) : null
  })

  useEffect(() => {
    if (token) {
      refresh(token)
    }
  }, [token])

  useEffect(() => {
    if (token && validateToken(token)) {
      sessionStorage.setItem('accessToken', JSON.stringify(token))
      refresh(token)
    } else {
      sessionStorage.removeItem('accessToken')
      refresh(null)
    }
  }, [token])

  const getUserId = () => {
    if (token) {
      return getUserIdFromToken(token)
    }
    return null
  }

  return { token, setToken, getUserId }
}
