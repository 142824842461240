import { MessageBase } from '@petshop/common'

export const unique = <T>(array: T[]) => {
  return [...new Set([...array])]
}

export const uniqueByKey = <T>(array: T[], propertyName: keyof T) => {
  return array.filter((e, i) => array.findIndex((a) => a[propertyName] === e[propertyName]) === i)
}

export const updatedDateSorterAsc = (a: { updated?: string }, b: { updated?: string }) =>
  new Date(a.updated!).getTime() - new Date(b.updated!).getTime()

export const createdDateSorterAsc = (a: { created?: string }, b: { created?: string }) =>
  new Date(a.created!).getTime() - new Date(b.created!).getTime()

export const createdDateSorterDesc = (a: { created?: string }, b: { created?: string }) =>
  new Date(b.created!).getTime() - new Date(a.created!).getTime()

export const prepareUniqueMessages = <T extends Omit<MessageBase, 'roomId'>>(messages: T[], sorter: (a: T, b: T) => number) => {
  const uniqueMessages = messages
    .sort((a, b) => new Date(b.updated!).getTime() - new Date(a.updated!).getTime())
    .filter((message, index, self) => self.findIndex((m) => m.id === message.id) === index)
  return uniqueMessages.sort(sorter)
}
