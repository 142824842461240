import { API_BASE_URL } from '../constants/env.ts'
import { FriendInfo } from '../types/entities/User.ts'
import { getAxios } from './axios.ts'
import { FriendsOnline } from '@petshop/common'

export async function fetchFriends(): Promise<FriendInfo[]> {
  return (await getAxios().get<FriendInfo[]>(`${API_BASE_URL}/friends`)).data
}
export async function fetchFriendsRequest(): Promise<FriendInfo[]> {
  return (await getAxios().get<FriendInfo[]>(`${API_BASE_URL}/friendrequest/list`)).data
}

export async function acceptFriendRequest(id: string) {
  return (await getAxios().post(`${API_BASE_URL}/friendrequest/accept/${id}`)).data
}

export async function rejectFriendRequest(id: string): Promise<{ success: boolean }> {
  return (await getAxios().delete(`${API_BASE_URL}/friendrequest/reject/${id}`)).data
}

export const sendFriendRequest = async (userId: string) => {
  return (await getAxios().post(`${API_BASE_URL}/friendrequest/send`, { to: userId })).data
}

export const cancelFriendRequest = async (userId: string) => {
  return (await getAxios().delete(`${API_BASE_URL}/friendrequest/cancel/${userId}`)).data
}

export const checkFriendRequestSent = async (friendId: string) => {
  return (await getAxios().get<{ hasSent: boolean }>(`${API_BASE_URL}/friendrequest/hassent/${friendId}`)).data
}

export const removeFriend = async (friendUserId: string) => {
  return (await getAxios().delete(`${API_BASE_URL}/friends/${friendUserId}`)).data
}

export const getFriendsOnline = async () => {
  return (await getAxios().get<FriendsOnline[]>(`${API_BASE_URL}/friends/online`)).data
}
