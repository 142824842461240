import axios from 'axios'

const token = sessionStorage.getItem('accessToken') ? JSON.parse(sessionStorage.getItem('accessToken') as string) : null

const instance = axios.create({
  headers: {
    Authorization: token ? `Bearer ${token}` : ''
  }
})

export const getAxios = () => instance

export function refresh(token: string | null) {
  setAuthHeader(token)
}

function setAuthHeader(token: string | null): void {
  if (token) {
    instance.defaults.headers.Authorization = `Bearer ${token}`
  } else {
    delete instance.defaults.headers.Authorization
  }
}
