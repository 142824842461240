import { getAxios } from './axios.ts'
import { API_BASE_URL } from '../constants/env.ts'
import { Room, RoomEnriched } from '../types/entities/Room.ts'

export const fetchRooms = async () => {
  return (await getAxios().get<RoomEnriched[]>(`${API_BASE_URL}/room`)).data
}

export const fetchRoom = async (id: string) => {
  return (await getAxios().get<Room>(`${API_BASE_URL}/room/${id}`)).data
}

export const putRoom = async (room: Room) => {
  return (await getAxios().put<Room>(`${API_BASE_URL}/room/${room.id}`, room)).data
}
