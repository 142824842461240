import { RoomChat } from '@petshop/common'
import { getAxios } from './axios.ts'
import { API_BASE_URL } from '../constants/env'
import { NewRoomChat } from '../types/entities/RoomChat.ts'

export const getRoomChatsByRoomAfter = async (roomId: string, after?: number) => {
  return (await getAxios().get<RoomChat[]>(`${API_BASE_URL}/room/${roomId}/chat/after/${after}`)).data
}

export const getRoomChatsByRoomRange = async (roomId: string, min: number, max: number) => {
  return (await getAxios().get<RoomChat[]>(`${API_BASE_URL}/room/${roomId}/chat/range/${min}/${max}`)).data
}

export const getAllRoomChatsAfter = async (after?: number) => {
  return (await getAxios().get<RoomChat[]>(`${API_BASE_URL}/room/chat/after/${after}`)).data
}

export const getAllRoomChatsRange = async (min: number, max: number) => {
  return (await getAxios().get<RoomChat[]>(`${API_BASE_URL}/room/chat/range/${min}/${max}`)).data
}

export const getRoomChatTree = async (roomId: string, messageId: string) => {
  return (await getAxios().get<RoomChat[]>(`${API_BASE_URL}/room/${roomId}/chat/${messageId}/tree`)).data
}

export const postRoomChat = async (roomId: string, message: NewRoomChat) => {
  return (await getAxios().post<RoomChat>(`${API_BASE_URL}/room/${roomId}/chat`, message)).data
}
export const putRoomChat = async (roomId: string, message: RoomChat) => {
  return (await getAxios().put<RoomChat>(`${API_BASE_URL}/room/${roomId}/chat/${message.id}`, message)).data
}

export const voteRoomChat = async (roomId: string, messageId: string, vote: 'up' | 'down') => {
  return (await getAxios().put<RoomChat>(`${API_BASE_URL}/room/${roomId}/chat/${messageId}/vote`, { vote })).data
}

export const unvoteRoomChat = async (roomId: string, messageId: string) => {
  return (await getAxios().delete<RoomChat>(`${API_BASE_URL}/room/${roomId}/chat/${messageId}/vote`)).data
}

export const removeRoomChat = async (roomId: string, messageId: string) => {
  return (await getAxios().delete<RoomChat>(`${API_BASE_URL}/room/${roomId}/chat/${messageId}`)).data
}
