import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth.tsx'
import { useEffect } from 'react'
import {
  userIdAtom,
  setFeedDataAtom,
  feedDataAtom,
  notificationsAtom,
  setNotificationsAtom,
  friendRequestsAtom,
  setFriendRequestsAtom,
  friendsAtom,
  setFriendsAtom,
  roomIdAtom,
  threadAtom,
  setMessageDataAtom,
  messageDataAtom,
  setRoomChatsAtom,
  roomChatsAtom,
  privateMessagesAtom,
  setPrivateMessagesAtom,
  privateDiscussionIdAtom,
  roomsAtom,
  privateDiscussionsAtom,
  setPrivateDiscussionsAtom,
  friendsOnlineAtom,
  setFriendsOnlineAtom
} from '../atoms'
import { useAtom } from 'jotai/index'
import { WEBSOCKET_URL } from '../constants/env.ts'
import ReconnectingWebSocket from 'partysocket/ws'
import {
  ModelType,
  FeedItem,
  FeedItemType,
  Message,
  ModelAction,
  Notification,
  RoomChat,
  PrivateMessage,
  FriendsOnline
} from '@petshop/common'
import { uniqueByKey } from '../utils/array.utils.ts'
import { FriendInfo } from '../types/entities/User.ts'

export default function AuthenticatedRoute() {
  const { token, setToken, getUserId } = useAuth()
  const [, setUserId] = useAtom(userIdAtom)
  const [feedData] = useAtom(feedDataAtom)
  const [, setFeedData] = useAtom(setFeedDataAtom)
  const [notifications] = useAtom(notificationsAtom)
  const [, setNotifications] = useAtom(setNotificationsAtom)
  const [friendRequests] = useAtom(friendRequestsAtom)
  const [, setFriendRequests] = useAtom(setFriendRequestsAtom)
  const [friends] = useAtom(friendsAtom)
  const [, setFriends] = useAtom(setFriendsAtom)
  const [messageData] = useAtom(messageDataAtom)
  const [, setMessages] = useAtom(setMessageDataAtom)
  const [roomChats] = useAtom(roomChatsAtom)
  const [, setRoomChats] = useAtom(setRoomChatsAtom)
  const [privateMessages] = useAtom(privateMessagesAtom)
  const [, setPrivateMessages] = useAtom(setPrivateMessagesAtom)
  const [privateDiscussions] = useAtom(privateDiscussionsAtom)
  const [, setPrivateDiscussions] = useAtom(setPrivateDiscussionsAtom)
  const [privateDiscussionId] = useAtom(privateDiscussionIdAtom)
  const [friendsOnline] = useAtom(friendsOnlineAtom)
  const [, setFriendsOnline] = useAtom(setFriendsOnlineAtom)
  const [, setRooms] = useAtom(roomsAtom)
  const [selectedRoomId] = useAtom(roomIdAtom)
  const [thread] = useAtom(threadAtom)

  const handleTimeout = () => {
    return setInterval(() => {
      if (!token) {
        console.log('clearing token')
        setToken(null)
        window.location.href = '/'
      }
    }, 60 * 1000) // 1 minute timeout
  }

  useEffect(() => {
    const timeout = handleTimeout()
    const userId = getUserId()
    if (userId) {
      setUserId(userId)
    }
    return () => clearInterval(timeout)
  }, [token])

  useEffect(() => {
    if (!token) return
    const ws = new ReconnectingWebSocket(`${WEBSOCKET_URL}?idToken=${token}`, [], { maxRetries: 10 })
    ws.onopen = () => {
      console.log('connected')
    }
    ws.onmessage = (event) => {
      const { modelType, action, ...rest } = JSON.parse(event.data) as { modelType: ModelType; action: ModelAction }
      console.log('received', modelType, action, rest)
      const processMessage = async () => {
        switch (modelType) {
          case ModelType.Thread:
            switch (action) {
              case ModelAction.Create:
                await setFeedData([...feedData, { ...rest, type: FeedItemType.Thread } as FeedItem])
                break
              case ModelAction.Delete:
                await setFeedData(feedData.filter((item) => item.id !== (rest as { id: string }).id))
                break
              case ModelAction.Update:
                await setFeedData(feedData.map((item: FeedItem) => (item.id === (rest as { id: string }).id ? { ...item, ...rest } : item)))
                break
            }
            break
          case ModelType.GlobalMessage:
            await setFeedData([...feedData, { ...rest, type: FeedItemType.GlobalMessage } as FeedItem])
            break
          case ModelType.RoomChat:
            switch (action) {
              case ModelAction.Create:
                await setFeedData([...feedData, { ...rest, type: FeedItemType.RoomChat } as FeedItem])
                if (selectedRoomId === (rest as { roomId: string }).roomId) {
                  await setRoomChats([...roomChats, rest as RoomChat])
                }
                setRooms(async (rooms) =>
                  (await rooms).map((room) =>
                    room.id === (rest as RoomChat).roomId ? { ...room, lastChat: rest as RoomChat, chatCount: room.chatCount + 1 } : room
                  )
                )
                break
              case ModelAction.Delete:
                await setFeedData(feedData.filter((item) => item.id !== (rest as { id: string }).id))
                if (selectedRoomId === (rest as { roomId: string }).roomId) {
                  await setRoomChats(roomChats.filter((item) => item.id !== (rest as { id: string }).id))
                }
                setRooms(async (rooms) =>
                  (await rooms).map((room) =>
                    room.id === (rest as { roomId: string }).roomId ? { ...room, chatCount: room.chatCount - 1 } : room
                  )
                )
                break
              case ModelAction.Update:
                await setFeedData(feedData.map((item: FeedItem) => (item.id === (rest as { id: string }).id ? (rest as FeedItem) : item)))
                await setRoomChats(roomChats.map((item) => (item.id === (rest as { id: string }).id ? (rest as RoomChat) : item)))
                break
            }
            break
          case ModelType.Message:
            switch (action) {
              case ModelAction.Create:
                await setFeedData(
                  feedData.map((item: FeedItem) => {
                    if (item.id === (rest as Message).threadId) {
                      return {
                        ...item,
                        messageCount: (item as { messageCount: number }).messageCount + 1
                      }
                    }
                    return item
                  })
                )
                break
              case ModelAction.Delete:
                await setFeedData(
                  feedData.map((item: FeedItem) => {
                    if (item.id === (rest as Message).threadId) {
                      return {
                        ...item,
                        messageCount: (item as { messageCount: number }).messageCount - 1
                      }
                    }
                    return item
                  })
                )
                break
              case ModelAction.Update: {
                const message = rest as Message
                if (selectedRoomId === message.roomId && thread?.id === message.threadId) {
                  await setMessages(messageData.map((m) => (m.id === message.id ? message : m)))
                }
              }
            }
            break
          case ModelType.Notification:
            await setNotifications(uniqueByKey([rest as Notification, ...notifications], 'id'))
            break
          case ModelType.FriendRequest:
            switch (action) {
              case ModelAction.Delete:
                await setFriendRequests(friendRequests.filter((fr) => fr.id !== (rest as FriendInfo).id))
                break
              case ModelAction.Create:
                await setFriendRequests([...friendRequests, rest as FriendInfo])
                break
              default:
                break
            }
            break
          case ModelType.Friends:
            switch (action) {
              case ModelAction.Delete:
                await setFriends(friends.filter((fr) => fr.id !== (rest as FriendInfo).id))
                break
              case ModelAction.Create:
                await setFriends([...friends, rest as FriendInfo])
                break
              default:
                break
            }
            break
          case ModelType.PrivateMessage:
            switch (action) {
              case ModelAction.Create: {
                const discussionId = (rest as { discussionId: string }).discussionId
                setPrivateDiscussions(
                  privateDiscussions.map((d) =>
                    d.discussionId === discussionId ? { ...d, unreadCount: d.unreadCount + 1, lastMessage: rest as PrivateMessage } : d
                  )
                )
                if (privateDiscussionId === discussionId) {
                  await setPrivateMessages([...privateMessages, rest as PrivateMessage])
                }
                break
              }
              case ModelAction.Delete: {
                const discussionId = (rest as { discussionId: string }).discussionId
                setPrivateDiscussions(
                  privateDiscussions.map((d) =>
                    d.discussionId === discussionId ? { ...d, unreadCount: d.unreadCount - 1, lastMessage: rest as PrivateMessage } : d
                  )
                )
                await setPrivateMessages(privateMessages.filter((item) => item.id !== (rest as { id: string }).id))
                break
              }
              case ModelAction.Update:
                await setPrivateMessages(
                  privateMessages.map((item) => (item.id === (rest as { id: string }).id ? (rest as PrivateMessage) : item))
                )
                break
              default:
                break
            }
            break
          case ModelType.OnlineStatus:
            switch (action) {
              case ModelAction.Create:
                await setFriendsOnline([...friendsOnline, rest as FriendsOnline])
                break
              case ModelAction.Delete:
                await setFriendsOnline(friendsOnline.filter((friend) => friend.userId !== (rest as FriendsOnline).userId))
                break
              default:
                break
            }
            break
          default:
            break
        }
      }
      processMessage().then(() => {})
    }
    ws.onclose = () => {
      console.log('disconnected')
    }

    return () => {
      console.log('useeffect cleanup')
      ws.close()
    }
  }, [token])

  return token ? <Outlet /> : <Navigate to="/login" replace />
}
