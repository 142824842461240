import { Container, ContainerProps } from '@mui/material'
import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
} & ContainerProps

export const MainContainer: FC<Props> = ({ children, sx, ...rest }) => {
  return (
    <Container
      disableGutters
      sx={{
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        p: 2,
        ...sx
      }}
      {...rest}
    >
      {children}
    </Container>
  )
}
