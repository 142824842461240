import { FallbackProps } from 'react-error-boundary'
import { isAxiosError, AxiosError } from 'axios'
import { MainContainer } from '../MainContainer.tsx'
import { Box, Typography, Button } from '@mui/material'
import logo from '../../assets/logo.jpeg'

export const errorFallback = ({ error }: FallbackProps) => {
  const handleBackToHome = () => {
    sessionStorage.removeItem('accessToken')
    window.location.href = '/login'
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderError = (error: any) => {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError
      return (
        <>
          <Typography
            variant="h1"
            sx={{
              fontSize: '156px',
              fontWeight: 'bold',
              background: 'linear-gradient(180deg, #78CF45, #30A049)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textAlign: 'center',
              zIndex: 2
            }}
          >
            {axiosError.response?.status}
          </Typography>

          <Typography
            variant="h6"
            sx={{
              fontSize: '20px',
              color: '#000',
              mb: 5,
              zIndex: 1
            }}
          >
            {axiosError.response?.statusText}
          </Typography>
        </>
      )
    }
    return (
      <>
        <Typography
          variant="h1"
          sx={{
            fontSize: '156px',
            fontWeight: 'bold',
            background: 'linear-gradient(180deg, #78CF45, #30A049)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textAlign: 'center',
            zIndex: 2
          }}
        >
          Oops!
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: '20px',
            color: '#000',
            mb: 5,
            zIndex: 1
          }}
        >
          {error.message}
        </Typography>
      </>
    )
  }

  return (
    <MainContainer>
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            position: 'relative',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor: '#fff',
            overflow: 'hidden'
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{
              position: 'absolute',
              top: '20px',
              left: '20px',
              width: '60px',
              height: '60px',
              zIndex: 3
            }}
          />
          <Box
            component="img"
            src="/tent.jpeg"
            alt="Not Found"
            sx={{
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '120%',
              height: 'auto',
              maxWidth: '700px',
              zIndex: 1
            }}
          />
          {renderError(error)}
          <Button
            variant="contained"
            onClick={handleBackToHome}
            sx={{
              background: 'linear-gradient(180deg, #78CF45, #30A049)',
              color: '#fff',
              width: '80%',
              height: '48px',
              fontWeight: 'bold',
              fontSize: '16px',
              px: 4,
              py: 1,
              borderRadius: '10px',
              zIndex: 1
            }}
          >
            Back to Home
          </Button>
        </Box>
      </Box>
    </MainContainer>
  )
}
