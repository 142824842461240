import * as React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { useSnackbarContext } from '../hooks/SnackbarContext.tsx'
import { lazy, Suspense } from 'react'
import { FullpageContainer } from '../components/error/FullpageContainer.tsx'
import { CircularProgress } from '@mui/material'
import AuthenticatedRoute from '../components/AuthenticatedRoute.tsx'

function Fallback() {
  return (
    <FullpageContainer>
      <h2>
        <CircularProgress /> Loading...
      </h2>
    </FullpageContainer>
  )
}

export const LayoutTemplate = () => {
  const [SnackbarComponents] = useSnackbarContext()
  const LoginPage = lazy(() => import('../pages/LoginPage.tsx'))
  const SignUpPage = lazy(() => import('../pages/SignUpPage/SignUpPage.tsx'))
  const HomeScreen = lazy(() => import('../pages/HomeScreenPage/HomeScreen.tsx'))
  const FriendsPage = lazy(() => import('../pages/FriendsPage/FriendsPage.tsx'))
  const FriendsRequestPage = lazy(() => import('../pages/FriendsRequestPage/FriendsRequestPage.tsx'))
  const ChatsPage = lazy(() => import('../pages/ChatsPage/ChatsPage.tsx'))
  const NotificationsPage = lazy(() => import('../pages/NotificationsPage/NotificationsPage.tsx'))
  const ProfileScreen = lazy(() => import('../pages/ProfilePage/ProfileScreen.tsx'))

  return (
    <React.Fragment>
      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/" element={<AuthenticatedRoute />}>
            <Route path="/home" element={<HomeScreen />} />
            <Route path="/friends" element={<FriendsPage />} />
            <Route path="/friends/requests" element={<FriendsRequestPage />} />
            <Route path="/chat" element={<ChatsPage />} />
            <Route path="/notifications" element={<NotificationsPage />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/profile/:id" element={<ProfileScreen />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {SnackbarComponents}
      </Suspense>
    </React.Fragment>
  )
}
