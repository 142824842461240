import { z } from 'zod'

export const NotificationTypeSchema = z.enum(['friend_request', 'friend_request_accepted', 'roomchat_message', 'story_message', 'private_message', 'general'])
/*export const NotificationSchema = z.object({
  id: z.string().optional(),
  title: z.string(),
  message: z.string(),
  read: z.boolean().optional(),
  threadId: z.string().optional(),
  fromId: z.string().optional(),
  from: z.string().optional(),
  toId: z.string(),
  to: z.string(),
  type: NotificationTypeSchema,
  created: z.string().optional(),
  updated: z.string().optional()
})*/

export const NotificationBaseSchema = z.object({
  id: z.string().optional(),
  title: z.string(),
  message: z.string(),
  to: z.string(),
  read: z.boolean().optional(),
  type: NotificationTypeSchema,
  created: z.string().optional(),
  updated: z.string().optional()
})


export const FriendRequestMetadataSchema = NotificationBaseSchema.extend({
  type: z.literal('friend_request'),
  metadata: z.object({
    from: z.string(),
  })
})

export const FriendRequestAcceptedMetadataSchema = NotificationBaseSchema.extend({
  type: z.literal('friend_request_accepted'),
  metadata: z.object({
    from: z.string(),
  })
})

export const RoomchatMessageMetadataSchema = NotificationBaseSchema.extend({
  type: z.literal('roomchat_message'),
  metadata: z.object({
    from: z.string(),
    roomId: z.string(),
  })
})

export const StoryMessageMetadataSchema = NotificationBaseSchema.extend({
  type: z.literal('story_message'),
  metadata: z.object({
    from: z.string(),
    threadId: z.string(),
  })
})

export const PrivateMessageMetadataSchema = NotificationBaseSchema.extend({
  type: z.literal('private_message'),
  metadata: z.object({
    from: z.string(),
  })
})

export const GeneralMetadataSchema = NotificationBaseSchema.extend({
  type: z.literal('general'),
  metadata: z.object({})
})

export const NotificationSchema = z.discriminatedUnion('type', [FriendRequestMetadataSchema, FriendRequestAcceptedMetadataSchema, RoomchatMessageMetadataSchema, StoryMessageMetadataSchema, PrivateMessageMetadataSchema, GeneralMetadataSchema])

export type Notification = z.infer<typeof NotificationSchema>
export type NotificationType = z.infer<typeof NotificationTypeSchema>
